$fs10: 0.625rem;
$fs12: 0.75rem;
$fs13: 0.813rem;
$fs14: 0.875rem;
$fs15: 0.937rem;
$fs16: 1rem;
$fs17: 1.062rem;
$fs18: 1.125rem;
$fs19: 1.19rem;
$fs20: 1.25rem;
$fs21: 1.313rem;
$fs22: 1.375rem;
$fs23: 1.4375rem;
$fs24: 1.5rem;
$fs25: 1.56rem;
$fs26: 1.625rem;
$fs27: 1.6875rem;
$fs28: 1.75rem;
$fs29: 1.8125rem;
$fs30: 1.875rem;
$fs35: 2.19rem;

$size-110: 6.875rem;
$size-100: 6.25rem;
$size-90: 5.625rem;
$size-80: 5rem;
$size-70: 4.375rem;
$size-60: 3.75rem;
$size-50: 3.125rem;
$size-40: 2.5rem;

$black: #303030;
$white: #f9f9f9;
$white-two: #f4f4f4;
$warm-grey: #ab9696;
$bottle-green: #115c04;


$font-Mont: "Montserrat";
$font-Journal: "Journalism";
$fontHandi: 'Oz Handicraft Win95BT',
arial;



html {

    @include media-breakpoint-down(xl) {
        font-size: 90%;
    }

    @include media-breakpoint-down(lg) {
        font-size: 79%;
    }

    @include media-breakpoint-down(sm) {
        font-size: 65%;
    }

}


body {
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 400;
    line-height: 1.52;
    background: #fff;
    color: $black;
    letter-spacing: 0.6px;
}

p {
    margin-bottom: 15px;
}

.med {
    font-weight: 500;
}

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

button {
    &:active {
        outline: none !important;
    }

    &:focus {
        outline: none !important;
    }
}

input {
    &:active {
        outline: none !important;
    }

    &:focus {
        outline: none !important;
    }
}

.top {

    background-color: #fff;

    @include media-breakpoint-down(md) {
        position: fixed;
        width: 100%;
        z-index: 3;
    }

    &__row {
        min-height: 4.625rem;
        padding-top: 12px;
        padding-bottom: 12px;

        @include media-breakpoint-down(md) {
            min-height: 3rem;
        }

        @include media-breakpoint-down(xs) {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__tel {
        font-size: $fs20;
        font-weight: 500;
        letter-spacing: 0.05rem;
    }
}

.navigation {
    @include media-breakpoint-up(lg) {
        display: block !important;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}


.offer {

    background: linear-gradient(to left, #121212 0%, rgba(64, 64, 64, 0) 61.74%) no-repeat center,
        url(../img/bg-sm.jpg) no-repeat center;
    background-size: cover;



    // @include media-breakpoint-down(lg) {
    //     background-size: 1200px 100%, 1200px 100%;
    // }

    // @include media-breakpoint-down(md) {
    //     background-size: 992px 100%, 992px 100%;
    //     padding-top: 4rem;
    // }

    // @include media-breakpoint-down(sm) {
    //     background-size: 768px 100%, 768px 100%;
    // }

    // @include media-breakpoint-down(xs) {
    //     background-size: 576px 100%, 576px 100%;
    // }

    &_service {
        background-image: url(../img/bg-srv.jpg);
    }

    &_portfolio {
        background-image: url(../img/bg-prt.jpg);
    }

    &__row {
        min-height: 36.25rem;
        padding-bottom: 5.94rem;
        position: relative;

        &_service,
        &_portfolio {
            height: 32.1rem;
            padding-bottom: 0;
        }
    }

    &__title {
        font-size: 7.5rem;
        color: #fff;
        font-family: $font-Journal;
        letter-spacing: 0.3rem;
        line-height: 1;

        @include media-breakpoint-down(sm) {
            font-size: 7.4rem;
        }

        @include media-breakpoint-down(xs) {
            font-size: 5.9rem;
            padding-right: 5px !important;
        }

        @media (max-width: 450px) {
            font-size: 3.9rem;
        }

        &_small {
            font-size: 5.1rem;
            line-height: 1.6;
            letter-spacing: 3.3px;

            @include media-breakpoint-down(sm) {
                font-size: 4.7rem;
            }

            @include media-breakpoint-down(xs) {
                font-size: 3rem;
            }
        }
    }

    &__desc {
        font-size: $fs24;
        color: #fff;
        letter-spacing: 0.0625rem;
        margin-top: 2rem;

        @media (max-width: 450px) {
            font-size: 1.9rem;
        }

    }

    &__line {
        border-top: solid 1px #ffffff;
        width: 7.375rem;
        margin-top: 1.5rem;
    }

    .main-button {
        margin-top: 4rem;
    }
}

.nav {
    text-align: center;

    &__inner {
        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }

}

a {
    color: $black;
    transition: .3s;

    &:hover {
        color: $bottle-green;
        text-decoration: none;
    }



}

.mobile-menu {

    width: 25px;
    height: 25px;
    display: inline-flex;
}

.nav-link {
    font-size: 16px;
    color: $black;
    transition: .3s;
    font-weight: 400;
    letter-spacing: 0.6px;
    display: block;
    position: relative;

    @include media-breakpoint-down(lg) {
        font-size: 14px;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        font-weight: 500;
    }

    &:hover {
        color: #179900;
    }

    &.active::before {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right, transparent, #115c04, transparent);
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
    }
}



@keyframes linkWidth {

    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.main-button {
    width: 13.44rem;
    height: 3.125rem;
    color: #fff;
    border: 1px solid #fff;
    font-size: $fs16;
    font-weight: 500;
    background: transparent;
    transition: .3s;
    letter-spacing: 0.0375rem;
    cursor: pointer;

    &:hover {
        background-color: $bottle-green;
        color: #fff;
        border: 1px solid $bottle-green;
    }

    @include media-breakpoint-down(lg) {
        width: 200px;
        height: 45px;
        font-size: 14px;
    }

    &_v2 {
        border: 1px solid $bottle-green;
        height: 45px;
        color: $black;
    }
}


.big-button {
    height: 148px;
    border: 1px solid $bottle-green;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: .3s;

    @include media-breakpoint-down(xl) {
        height: 80px;
    }

    @include media-breakpoint-down(xs) {
        height: 65px;
    }


    &:hover {
        background-color: $bottle-green;
        color: #fff;

        .big-button__arrow {
            display: inline-flex;
            fill: #fff;
            animation: moveRight;
            animation-duration: .5s;
            animation-fill-mode: both;

            & path {
                fill: #fff;
            }
        }
    }
}


.main-title {
    font-size: 37px;
    letter-spacing: 1.7px;
    font-family: 'Roboto Slab';
    text-transform: uppercase;
    line-height: 1.3;

    @include media-breakpoint-down(md) {
        font-size: 34px;
    }

    @include media-breakpoint-down(md) {
        font-size: 25px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 20px;
    }

    &_half {
        @include media-breakpoint-down(lg) {
            font-size: 35px;
            letter-spacing: 1.3px;
        }

        @include media-breakpoint-down(md) {
            font-size: 25px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            letter-spacing: 1px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 21px;
        }
    }
}

.med-title {
    font-family: 'Roboto Slab';
    font-size: 22px;
    line-height: 1.35;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 15px;

    @include media-breakpoint-down(xs) {
        font-size: 18px;
    }


    @include media-breakpoint-down(xs) {
        font-size: 16px;
        letter-spacing: 1px;
    }
}

.main-desc {
    line-height: 1.52;
    letter-spacing: .0511rem;
    font-size: $fs18;

    @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
        letter-spacing: .07rem;
    }
}

.main-ul {
    margin-top: .8rem;
    padding-left: 2.3rem;
}

.main-li {
    font-size: $fs16;
    letter-spacing: 0.044rem;
    margin-top: 0.56rem;
    list-style: none;

    &:before {
        content: '';
        display: inline-block;
        width: 0.69rem;
        height: 0.69rem;
        border-radius: 50%;
        background-color: $bottle-green;
        position: relative;
        right: 1.4rem;

        @include media-breakpoint-down(lg) {
            right: 1.1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 1.3rem;
        letter-spacing: .07rem;
        margin-top: .8rem;
    }
}


.m-form {

    &__label {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.6px;
    }

    &__input {
        border: 1px solid #939393;
        padding: 10px 19px;
        height: 45px;
        background-color: transparent;
    }

    .form-group {
        position: relative;

        .error {
            position: absolute;

            @include media-breakpoint-down(sm) {
                font-size: 11px;
            }
        }
    }
}


#input-comment {
    height: 10rem;
}



::-webkit-input-placeholder {
    color: #b2b2b2;
    font-size: 16px;
    letter-spacing: 0.6px;
}

/* webkit */
::-moz-placeholder {
    color: #b2b2b2;
    font-size: 16px;
    letter-spacing: 0.6px;
}

/* Firefox 19+ */
:-moz-placeholder {
    color: #b2b2b2;
    font-size: 16px;
    letter-spacing: 0.6px;
}

/* Firefox 18- */
:-ms-input-placeholder {
    color: #b2b2b2;
    font-size: 16px;
    letter-spacing: 0.6px;
}

/* IE */

.main-form {
    background-color: $white;
    margin-top: 6rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background: #f9f9f9;

    &_v2 {
        background-color: transparent;
        padding-top: 3.8rem;
        padding-bottom: 7.25rem;
    }
}

#nav-icon4 {
    display: inline-flex;
    width: 30px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-left: 15px;

    @include media-breakpoint-down(xs) {
        width: 25px;
        height: 20px;
    }
}

#nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #303030;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

}


#nav-icon4:hover span {
    background: #115c04;
}




/* Icon 4 */



#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;

    @include media-breakpoint-down(xs) {
        top: 8px;
    }
}

#nav-icon4 span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;

    @include media-breakpoint-down(xs) {
        top: 16px;
    }
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 4px;
}

#nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 4px;

    @include media-breakpoint-down(xs) {
        top: 18px;
    }
}

.explain {
    padding-top: 4.8rem;
    padding-bottom: 5rem;

    img {
        box-shadow: 0 0 15px #d6d6d6;
    }
}

.green-block {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(17, 92, 4, 0.8) 38.17%, rgba(196, 196, 196, 0) 98.29%) no-repeat center/cover;
    transition: .3s;
}


.services {
    padding-top: 3rem;
    padding-bottom: 6.125rem;

    &__row {
        margin-top: 2rem;
    }
}

.service {
    padding: 30px 18px 40px 18px;
    position: relative;

    &__head {
        min-height: 80px;
    }

    &__title {
        color: #fff;
        font-size: 17px;
        line-height: 1.58;
        letter-spacing: 0.7px;
        font-weight: 600;

        @include media-breakpoint-down(lg) {
            font-size: 15px;
        }
    }

    &__body {
        min-height: 295px;

        @include media-breakpoint-down(xl) {
            min-height: 275px;
        }

    }

    &__desc {
        color: #fff;
        font-size: 16px;
        line-height: 1.34;

        @include media-breakpoint-down(lg) {
            font-size: 15px;
        }
    }

    &__more {
        position: relative;
        color: #fff !important;
        line-height: 1.48;
        letter-spacing: 0.7px;
        font-weight: 500;


        &::after {
            display: none;
            position: relative;
            content: '';
            width: 40px;
            height: 10px;
            left: 15px;
            background: url(../svg/Arrow.svg) no-repeat center;
            background-size: contain;
        }

        &:hover {
            text-decoration: none;
            color: #fff !important;
        }
    }

    &:hover {
        .green-block {
            opacity: 1;
        }
    }
}

.service__more {
    cursor: pointer;
}

.service__more:hover::after {
    display: inline-block;
    animation: fadeInLeft;
    animation-duration: .5s;
    animation-fill-mode: both;
}

.etaps {

    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background: #f9f9f9;

    &__item {
        text-align: center;
        background: #f9f9f9;
        width: 206px;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: 114px;
        z-index: 3;



        @include media-breakpoint-down(xl) {
            margin-right: 65px;
        }

        @include media-breakpoint-down(lg) {
            font-size: 16px;
            margin-right: 16px;
        }

        @include media-breakpoint-down(md) {
            margin-right: 0;
            width: 178px;
        }

        @include media-breakpoint-down(xs) {
            width: 159px;
            font-size: 13px;
        }

    }

    &__item__row {
        position: relative;
    }

    &__dots {
        position: absolute;
        z-index: 1;
        width: 1120px;
        height: 104px;
        border: 1px dashed #115c04;
        border-left: none;
        border-radius: 89px;
        border-left: none;
        top: 76px;
        left: 89px;

        @include media-breakpoint-down(xl) {
            width: 968px;
        }

        @include media-breakpoint-down(lg) {
            height: 84px;
            top: 60px;
            width: 813px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.projects {
    margin-top: 5rem;

    &__item {
        &_service {
            @include media-breakpoint-up(xxl) {
                box-shadow: inset 0px 0px 0px 1px #115c04;
            }
        }
    }

    &__inner {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 22px;
        padding-bottom: 31px;
        height: 309px;
        color: #ffffff;
        align-content: flex-start;
        position: relative;
        background: no-repeat center;
        background-size: cover;

        &:hover {
            .green-block {
                opacity: 1;
            }
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            height: 270px;
        }

        &_px15 {
            @include media-breakpoint-down(xs) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    &__title {
        font-family: "Journalism";
        text-transform: uppercase;
        font-size: 23px;
        letter-spacing: 1.2px;
        height: 85px;
        margin-bottom: 1rem;

        @include media-breakpoint-down(lg) {
            font-size: 23px
        }

        @include media-breakpoint-down(md) {
            font-size: 20px;
            height: 76px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            height: 70px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 22px;
            height: 88px;
        }
    }

    &__desc {
        line-height: 1.34;
        letter-spacing: 0.6px;
        font-size: 16px;
        height: 146px;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            font-size: 15px;
        }

        @include media-breakpoint-down(md) {
            height: 130px;
            font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }

        &_fz {
            &_14 {
                @include media-breakpoint-down(xl) {
                    font-size: 14px;
                }
            }
        }
    }

    &__more {
        position: relative;
        color: #fff !important;
        line-height: 1.48;
        letter-spacing: 0.7px;
        font-weight: 500;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }


        &::after {
            display: none;
            position: relative;
            content: '';
            width: 40px;
            height: 10px;
            left: 15px;
            background: url(../svg/Arrow.svg) no-repeat center;
            background-size: contain;
        }

        &:hover {
            text-decoration: none;
            color: #fff !important;
        }
    }

    &__more:hover::after {
        display: inline-block;
        animation: fadeInLeft;
        animation-duration: .5s;
        animation-fill-mode: both;
    }
}


.comments {
    margin-top: 6.18rem;
    margin-bottom: 8rem;

    &__row {
        margin-top: 3.125rem;

    }
}



.comment {
    border: 1px solid $bottle-green;
    padding: 2.5rem 1.56rem 2.5rem 1.56rem;

    @include media-breakpoint-down(sm) {
        padding: 2rem 1rem 1.5rem 1rem;
    }

    @include media-breakpoint-down(xs) {
        padding: 1.5rem 0 1rem 0;
    }

    &__name {
        font-family: 'Roboto Slab';
        font-size: 30px;
        line-height: .8;
        letter-spacing: 1.2px;
    }

    &__date {
        font-weight: 500;
    }

    &__desc {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.6px;
        color: #303030;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 14px;
        }
    }
}


.footer {

    a {
        color: #fff;
        transition: .3s;

        &:hover {
            text-decoration: none;
            color: #b5b5b5;
        }
    }

    background-color: #115c04;
    color: #fff;
    padding-top: 25px;
    padding-bottom: 25px;

    &__link {
        font-family: $font-Mont;
        font-size: 18px;
        font-weight: 300;
        line-height: 2;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.71;

    }

    &__text {
        font-size: 16px;
        font-weight: 300;
    }
}

.modal-header {
    border: none;
}

.modal-content {
    border-radius: 0;

    @include media-breakpoint-down(xl) {
        font-size: 16px;
    }
}

.modal {

    &_content {
        font-family: $font-Mont;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.7px;
        color: #000000;
    }

    &__gallery {
        img {
            box-shadow: 0 0 15px #d6d6d6;
        }
    }
}

@media (min-width: 576px) {
    .modal-lg {
        max-width: 556px !important;
    }
}

@media (min-width: 767px) {
    .modal-lg {
        max-width: 737px !important;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 978px !important;
    }
}

@media (min-width: 1200px) {
    .modal-lg {
        max-width: 1070px !important;
    }
}


.contacts {
    height: 750px;

    @include media-breakpoint-down(md) {
        height: auto;
    }

    &__row {
        @include media-breakpoint-down(md) {
            padding-top: 6.25rem;
        }
    }

    &__map {
        height: 750px;
        width: 100%;

        @include media-breakpoint-down(md) {
            height: 500px;
        }
    }

    &__item {
        flex-wrap: nowrap;
    }

    &__item-left {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.45;
        width: 120px;
        flex-shrink: 0;

        @include media-breakpoint-down(xs) {
            font-size: 15px;
            width: 90px;
        }
    }

    &__item-right {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.7;

        @include media-breakpoint-down(xs) {
            font-size: 14px;
        }
    }

    &__item-inner {
        flex-wrap: wrap;
    }

    &__item-here {
        flex-shrink: 0;
        width: 100%;
    }

    &__link {
        text-decoration: underline;
        font-size: 16px;

        &:hover {
            text-decoration: none;
        }

        @include media-breakpoint-down(xs) {
            font-size: 14px;
        }
    }
}

.error_name,
.error_tel {
    font-size: 10px;
    position: absolute;

    @include media-breakpoint-down(xs) {
        font-size: 9px;
    }
}

.pagination {
    .disabled {
        display: none;
    }

    .page-link {
        color: #115c04;
    }

    .page-item:first-child .page-link {
        border-radius: 0;
    }

    .page-item:last-child .page-link {
        border-radius: 0;
    }
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #115c04;
    border-color: #115c04;
}

.page-link:hover {
    border-color: #115c04;
}

.area {
    position: absolute;
    left: 15px;
    top: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
        font-size: 13px;
        top: 15px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 12px;
        top: 5px;
    }
}


//анимации


@keyframes moveRight {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(15px);
    }
}


@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.about {
    padding-top: 3.5rem;
}

.portfolio {
    margin-top: 5rem;
}



.main-carousel {
    &__img {
        display: block;
        padding-right: 15px;
        box-shadow: 0 0 5px #d6d6d6;

        

        @include media-breakpoint-down(sm) {
            width: 520px;
            height: auto;
            padding-right: 10px;
        }

        @include media-breakpoint-down(xs) {
            width: 365px;
            height: auto;
        }

        &_beton {
            @include media-breakpoint-down(xs) {
                height: 230px;  
            }

            @media screen and (max-width: 340px) {             
                height: 194px;
            }
        }
    }
}

.flickity-prev-next-button.previous {
    @include media-breakpoint-down(sm) {
        left: 0!important;    
    }

    @include media-breakpoint-down(xs) {
        width: 25px;
        height: 25px;      
    }
}

.flickity-prev-next-button.next {
    @include media-breakpoint-down(sm) {
        right: 0!important;   
    }

    @include media-breakpoint-down(xs) {
        width: 25px;
        height: 25px;      
    }
}

.style {

    &__item {
        max-width: 410px;
    }

    &__name-desc {
        font-size: 16px;

        @include media-breakpoint-down(xl) {
            font-size: 13px;             
        }

        @include media-breakpoint-down(lg) {
            font-size: 12px;     
        }

        @include media-breakpoint-down(xs) {
            font-size: 14px;     
        }
    }

    &__photo {
        background: no-repeat center;
        background-size: cover;
        height: 235px;
        width: 100%;
        box-shadow: 0 0 10px rgba(0,0,0, .15);

        @include media-breakpoint-down(lg) {
            height: 160px;     
        }

        @include media-breakpoint-down(xs) {
            height: 235px;     
        }
    }
}