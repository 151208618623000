

@font-face {
    font-family: 'Journalism';
    src: local('Journalism');
    src: url('../fonts/Journalism.ttf') format('truetype');
         
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Roboto Slab';
	src: url('../fonts/RobotoSlab-Regular.eot');
	src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
		url('../fonts/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoSlab-Regular.woff') format('woff'),
		url('../fonts/RobotoSlab-Regular.ttf') format('truetype');
	font-weight: normal;
	font-display: 'fallback';
	font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Light');
    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat-Light.woff') format('woff'),
         url('../fonts/Montserrat-Light.ttf') format('truetype');
         font-display: 'fallback';     
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Regular');
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat-Regular.woff') format('woff'),
         url('../fonts/Montserrat-Regular.ttf') format('truetype');
         font-display: 'fallback';     
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat-Medium');
    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Montserrat-Medium.woff') format('woff'),
         url('../fonts/Montserrat-Medium.ttf') format('truetype');
         font-display: 'fallback';     
    font-weight: 500;
    font-style: normal;
}

